exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-addressbook-js": () => import("./../../../src/pages/addressbook.js" /* webpackChunkName: "component---src-pages-addressbook-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-hubble-js": () => import("./../../../src/pages/hubble.js" /* webpackChunkName: "component---src-pages-hubble-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-measure-js": () => import("./../../../src/pages/measure.js" /* webpackChunkName: "component---src-pages-measure-js" */),
  "component---src-pages-toucantype-js": () => import("./../../../src/pages/toucantype.js" /* webpackChunkName: "component---src-pages-toucantype-js" */)
}

